:root {
  /* MDT colors */
  --primary: #5ABAA2;
  --secondary: #E74824;
  --global-yellow: #fcc504;
  --global-background-yellow: #fdc731;
  --global-black: #3c3c3c;
  --global-dark-grey: #5e5e5e;
  --global-black-background: #111111;
  --global-white-background: #ffffff;
  --global-grey-background: #f5f5f5;
  --global-light-gray: whitesmoke;

  /* HUMINTS colors */
  --light-orange: #f9bc9966;
  --light-blue: #ebffff90;
  --light: rgb(249, 249, 249);
  --light-bg: rgba(238, 238, 238, 0.655);
  --light-alt: #c7c7c7c6;
  --dark: #232f44;
  --dark-alt: #45484f;

  /* TINO colors */
  --yellow-logo: #FCDF30;
  --yellow-soft: #f9e8b2;
  --strong-green: #219F8E;
  --black-logo: #2a2a2a;
  --strong-blue: #304DFC;
  --soft-blue: #336BB0;
  --orange: #FC7930;
  --light-white: #f1f3f6;
  --background-grey: #f7f7f7;
  --background-grey-btn: #c6c6c6c6;
  --icons-grey: #5e5e5e;

  /* general */
  --speed: 500ms;
  --border-radius: 10px;
  --border: 1px solid #b9b9b9;

  /* culture & kunts */
  --culture-green: #61CE70;
  --culture-orange: #ED6C1A;
  --culture-orange-button: #E74824;
  --culture-black: #111111;
  --culture-turquoise: #5ABAA2;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/* GENERAL */
body {
  text-align: center;
  line-height: 1.4;
  color: #444;
}

p {
  color: #444;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fira Sans", sans-serif;
  line-height: 1.8;
  color: var(--dark-alt);
  font-weight: 400;
}

h1 {
  font-size: 30px;
  margin-bottom: 22px;
  font-weight: 700;
}

h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 1.4;
}

h3 {
  font-size: 21px;
  font-weight: 500;
  line-height: 1.4;
}

h4 {
  font-size: 20px;
  font-weight: 500;
  color: var(--primary);
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
}

h5 {
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: var(--primary);
  line-height: 1.4;
}

h6 {
  font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
}

a {
  color: var(--primary);
  text-decoration: none;
}

a:hover,
.fa-whatsapp:hover {
  color: var(--soft-blue);
}

.download-link {
  color: var(--black-logo);
  text-decoration: none;
}

.download-link:hover {
  color: var(--primary);
}

button,
select {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin: 10px;
}

select {
  min-width: 130px;
  margin: 5px 0px;
}

input {
  border: none;
  border-radius: 5px;
  background-color: var(--light-white);
  cursor: pointer;
  font-size: 1rem;
  margin: 10px 0px;
  padding: 10px;
  width: 100%;
}

.yellow-btn {
  background-color: var(--global-yellow);
  color: var(--black-logo);
  border: none;
}

.greyButton {
  background-color: var(--background-grey-btn);
  color: var(--black-logo);
  border: none;
}

.uploadFileBtn {
  background-color: var(--primary);
  color: white;
  padding: 2px 4px 2px 8px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin: 10px;
}

button:hover,
.uploadFileBtn:hover {
  background-color: var(--global-yellow) !important;
  color: var(--black-logo);
  border: none;
}



.yellow-btn:hover {
  background-color: var(--background-grey-btn);
  color: var(--black-logo);
  border: none;
}

button:disabled,
button[disabled],
select:disabled,
input:disabled {
  background-color: #c5c5c5 !important;
  color: #919191;
  cursor: default;
  border-radius: 5px;
}

.transparentBtn {
  background-color: transparent;
  color: var(--dark-alt);
  border: none;
  cursor: pointer;
  padding: 5px 0px;
  margin: 0px;
}


.transparentBtn i {
  margin-right: 5px;
}

.transparentBtn:hover {
  background-color: transparent !important;
  color: var(--primary) !important;
  border: none;
}

.transparentBtn.delete:hover {
  background-color: transparent !important;
  color: var(--secondary) !important;
  border: none;
}

.successMessage {
  color: var(--strong-green);
  font-size: 1.1rem;
  font-weight: 500;
}

.errorMessage {
  color: var(--secondary);
  font-size: 1rem;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th {
  color: var(--black-logo);
  font-weight: 500;
  /* background-color: whitesmoke; */
  border-bottom: 0.5px solid #cfcece !important;
}

th,
td {
  text-align: left;
  padding: 10px;
  /* border: 1px solid #b9b9b9; */
}

/* 
tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* icons */

.fas,
.fa-regular {
  margin-right: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.fa-whatsapp {
  color: #4b4b4b !important;
  cursor: pointer;
  margin-right: 10px;
  font-size: '0.95em';
}

.fa-file {
  margin: 45px;
}


.fa-trash,
.fa-pencil,
.fa-file-export,
.fa-upload {
  padding: 10px;
}

.fa-trash:hover {
  color: var(--secondary);
}


/* table sorting in results page */
.fa-sort-up,
.fa-sort-down,
.fa-sort {
  color: var(--primary);
  margin-left: 5px;
  cursor: pointer;
}

/* Tooltip */
.tooltip {
  padding: 10px;
  font-size: 0.9rem;
  text-align: center;
  font-weight: 400;
  width: 40vw !important;
  border-radius: 20px;
  z-index: 1;
}


/* MAIN LAYOUT */

header {
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #b9b9b9; */
  background-color: white;
  z-index: 100;
  position: relative;
  width: 100%;
  /* box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.1); */
}

/* left-side of header */
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
}

.logo-img {
  display: flex;
  flex-direction: column;
  width: 50px;
  margin-right: 15px;
}

.logo-text>h6 {
  margin: 0;
  color: var(--black-logo);
  font-weight: 500;
  font-size: 1.1rem;
  letter-spacing: 1.1px;
}

.logo-text>h4,
.logo-text>p {
  color: var(--black-logo);
}

.login-header {
  align-content: center;
  align-items: center;
  margin: 0px;
  width: 100%;
}

/* right side of header (user menu) */

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-container p {
  color: #555;
  font-size: 1.3rem;
  font-weight: 500;
}

.menu-items {
  display: flex;
  list-style: none;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 15px;
}

.menu-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-trigger>p {
  margin-right: 20px;
}

.navbar-icon {
  padding: 5px;
}

.navbar-icon:hover {
  color: #2a2a2a !important;
  background-color: var(--global-yellow) !important;
}

.dropdown-menu {
  position: absolute;
  top: 65px;
  right: 0px;
  border-radius: var(--border-radius);
  border-top-right-radius: 0%;
  border-top-left-radius: 0%;
  width: 300px;
  padding: 10px;
  z-index: 3;
  border: 0.5px solid #b9b9b9;
  background-color: white;
  border-top: 0;
}

.dropdown-menu::before {
  content: '';
  position: absolute;
  top: -1px;
  right: 20px;
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

.dropdownItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  color: var(--dark-alt);
}

.dropdownItem h6 {
  font-size: 1.05rem;
}

.dropdownItem p {
  font-size: 0.95rem;
  color: #8a8a8a;
}

.mobile-closing {
  display: none;
}

/* FOOTER */
.footer {
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-family: "Fira Sans", sans-serif;
  border-top: 1px solid rgba(161, 153, 153, 0.106);
}

/* MAIN LAYOUT */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background-grey);
  min-height: 92vh;
}

.page-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  padding: 30px 20px 20px;
  gap: 5px;
}

.page-title>h3 {
  font-size: 1.4rem;
}

.page-title>h5 {
  font-size: 1.1rem;
}

.page-content {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
  width: 85vw;
  min-height: 60vh;
}

.element-box {
  margin: 5px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}


.element-title,
.element-title-green,
.element-title-yellow {
  background-color: white;
  border-radius: 20px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  padding: 10px 20px;

}

.element-title-green {
  background-color: var(--primary);
  border: 0.5px solid #b9b9b9;
}


.element-title-yellow {
  background-color: var(--global-yellow);
  border: 0.5px solid #b9b9b9;
  border-bottom: 0px;
}

.element-title-green>h5,
.element-title-green>h6 {
  color: white;
}

.element-body {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0%;
  border: var(--border);
}

.text-section {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.bottomNavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 20px;
}

.pagination {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  padding: 0px 30px;
  width: 100%;
}

.MuiSelect-select.MuiSelect-outlined {
  padding: 8px 10px !important;
  text-align: left;
  border: none;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 10px !important;
  text-align: left;
}

/* focus for input: */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--primary) !important;
}


/* PAGES */

/* LOGIN PAGE */
.login-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background-grey);
  height: 100vh;
}

.login-form-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  min-width: 30vw;
  box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

}

.login-form-title {
  margin-bottom: 20px;

}

.login-form input {
  background-color: white !important;
  color: var(--black-logo) !important;
  border: 0.5px solid var(--primary);
}

.login-form p {
  margin-bottom: 10px;
}

.login-form input:focus {
  outline: 1px solid var(--primary);
}

.login-form input::placeholder {
  color: var(--black-logo) !important;
}

.signin-help {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  gap: 20px;
}

.signin-help button,
.signin-help a {
  color: var(--black-logo);
  text-decoration: none;
  font-size: 0.9rem;
  text-align: left;
}

.signin-help a:hover {
  color: var(--strong-green) !important;
}

.otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 30vw;
}

/* LANDING PAGE */

.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  box-shadow: 0px 0px 5px 0px rgba(33, 33, 33, 0.09);
}

.landing-header .MuiSelect-select {
  padding: 8px 15px !important;
  width: 80px;
  text-align: left;
  border: none;
  background-color: var(--global-black);
  color: var(--light-white);
}

/* change also the pointer symbol inside muiselect */
.landing-header .MuiSelect-icon {
  color: var(--light-white);
}

.landing-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-logo h1 {
  font-size: 1.5rem;
  margin: 0px;
}

.loginLPButton,
.languageBtn {
  background-color: var(--global-black) !important;
  color: white !important;
  border: none;
  border-radius: 5px;
  margin: 0px 0px 0px 15px !important;
  padding: 10px;
}

.loginLPButton:hover,
.landing-header .MuiSelect-select:hover,
.landing-header .MuiSelect-icon:hover {
  background-color: var(--global-background-yellow) !important;
  color: var(--global-black) !important;
}

.languageBtn:focus,
.landing-header .MuiSelect-select:focus {
  outline: none;
}

.lottie-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1vh;
  width: 100%;
}

.landing-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;
  gap: 10px;
}

.landing-container button {
  padding: 15px;
  font-size: 1.1rem;
}

.hero-section {
  display: flex;
  width: 100%;
  gap: 50px;
  padding: 20px;
}

.hero-left-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-align: left;
  padding: 40px;
}

.hero-right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
}

.hero-right-container img {
  width: 100%;
  padding: 0px;
  border-radius: 20px;
  border: 0.5px solid #b9b9b9;
  box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.25);
}


.hero-left-container h1 {
  font-size: 2.5rem;
  color: var(--black-logo);
  margin: 5px 0px;
  line-height: 1.3;
}

.heroSection h2 {
  font-weight: 500;
}

.hero-left-container p,
.hero-left-container h2 {
  color: var(--black-logo);
  text-align: left;
}

.hero-left-container p {
  font-size: 1.4rem;
  margin: 5px 0px;
}

.hero-left-container button {
  margin: 30px 0px;
}


.featureSection {
  display: flex;
  padding: 0px 40px;
  gap: 5px;
}

.featureSection.div1 p,
.featureSection.div2 p,
.featureSection.div4 p {
  font-size: 1.3rem;
}

.featureSection.div3 {
  flex-direction: column;
  align-items: center;
  width: 80vw;
  padding: 20px;
  margin: 50px;
  font-size: 1.3rem;
}

.featureSection h5 {
  font-size: 1.3rem;
}

.featureSection-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
}

.featureSection-left-container img {
  width: 100%;
  max-width: 350px;
  border-radius: 20px;
  border: 0.5px solid #b9b9b9;
  box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.25);
}

.featureSection-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding-right: 40px;
  gap: 5px;
}

.featureSection.div2>.featureSection-right-container {
  padding-left: 40px;
  padding-right: 0px;
}

.landing-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: white;
  gap: 10px;
  font-size: 1rem;
}

.landing-footer div {
  display: flex;
  gap: 15px;
}

.landing-footer a {
  color: white;
  text-decoration: none;
}

.landing-footer a:hover {
  text-decoration: underline;
}

/* ANIMATIONS FOR THE HEADERS*/
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.animated {
  animation-duration: 1.2s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.hidden {
  opacity: 0;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-from-left {
  animation-name: slideInFromLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
}



/* SIGNUP PAGE */
.sign-up-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.signup-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.loginBtn {
  margin: 0px 10px;
  width: 100%;
}

/* HOMEPAGE  */
.homepage-container {
  display: flex;
  background-color: var(--background-grey);
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 92vh;
  width: 100%;
  padding: 20px 60px 20px 100px;
}

/* if user didn't replace temp password */
.homepage-alert {
  margin: 15px 0px;
  width: 55vw;
}

.homepage-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55vw;
}

.homepage-group-select-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: left;
}

.homepage-group-select {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.homepage-onboarding-text {
  padding: 20px 30px;
  margin: 20px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.1);
  width: 55vw;
}

.homepage-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  gap: 20px;
}

.teacher-container,
.students-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.element-title.apps {
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
  border: 1px solid #b9b9b9;
  border-bottom: 0px;
  width: 100%;
  z-index: 1;
}

.element-title.apps h6 {
  font-weight: 500;
}

.apps-container {
  /* display: grid; */
  /* grid-template-columns: repeat(3, 1fr); */
  display: flex;
  justify-content: center;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  z-index: 1;
  border: 1px solid #b9b9b9;

}

/* individual buttons for app pages in homepage */
.app-box {
  display: flex;
  justify-content: center;
  font-family: "Fira Sans", sans-serif;
  font-size: 1.1rem;
  align-items: center;
  width: 140px;
  height: 120px;
  border-radius: 20px;
  color: white;
  grid-row: 1;
  box-shadow: 0px 0px 10px 0px rgba(33, 33, 33, 0.1);
  transition: all 0.3s ease;
}

.app-box:disabled {
  cursor: not-allowed;
  border-radius: 20%;
  background-color: #c5c5c5;
  color: #949292;
  cursor: default;
}

.app-box:disabled:hover {
  background-color: #c5c5c5;
  color: #949292;
  cursor: default;
}

.app-box:hover {
  background-color: var(--global-yellow);
  cursor: pointer;
  color: var(--dark-alt);
}

/* if tenant is C&K then put shapes instead of buttons */
.ck-app-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 130px;
  border-radius: 20px;
  color: #ffffff;
  font-family: "Manropen", sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: #111111 !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: all 0.3s ease;
}

.ck-app-box:not(:disabled):hover {
  background-color: rgb(235, 235, 235) !important;
  color: var(--dark-alt);
  transform: translateY(2px);
  box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.app-box:not(:disabled):hover {
  /* background-color: rgb(235, 235, 235) !important;
  color: var(--dark-alt); */
  transform: translateY(2px);
  box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.2);
}


/* overlay for ck background image */
.bg-overlay {
  position: relative;
}

.bg-overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.372);
  z-index: 1;
}

.bg-overlay>* {
  position: relative;
  z-index: 2;
}

.ck-bg-icon {
  width: 100%;
  height: 100%;
}

.ck-bg-icon>img {
  width: 50px;
  position: absolute;
  top: 100;
  left: 0;
  z-index: 0;
  margin-left: 40px;
  margin-top: 40px;
  transform: rotate(230deg);
}

.ck-bg-icon-green>img {
  width: 100px;
  position: absolute;
  top: 100;
  right: 0;
  z-index: 0;
  margin-right: 50px;
  margin-top: 100px;
  transform: rotate(10deg);
}

.ck-bg-icon .red-icon {
  width: 100px;
  position: absolute;
  top: 100;
  right: 0;
  z-index: 0;
  margin-left: 50px;
  margin-top: 180px;
  transform: rotate(120deg);
}

.ck-red-icon-right>img {
  width: 60px;
  position: absolute;
  top: 100;
  right: 0;
  z-index: 0;
  margin-right: 50px;
  margin-top: 20px;
  transform: rotate(200deg);
}

.ck-button-icon>img {
  width: 25px;
  margin: 5px;
  transition: transform 0.3s ease;
}

.ck-app-box:hover .ck-button-icon img {
  transform: rotate(35deg);
  /* Rotate the image by 10 degrees on hover */
}

.ck-button-icon .ck-button-green {
  width: 35px;
}

.ck-app-box:not(:disabled):hover {
  background-color: rgb(235, 235, 235) !important;
  color: var(--dark-alt);
}


/* ONBOARDING FLOW */
.onboarding-banner {
  max-height: 8vh;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary);
  color: var(--light-white);
  box-shadow: 0px -5px 20px 0px rgba(105, 104, 104, 0.181);
  z-index: 2;
}

.onboarding-closing-icon-container {
  display: flex;
  padding: 10px;
}

.onboarding-content-container {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  justify-content: flex-start;
  width: 80%;
  padding: 15px;
  margin-top: 15px;
  min-height: 65vh;
  background-color: white;
}

.onboarding-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-title p {
  width: 90%;
  font-size: 1.05rem;
  margin-top: 10px;
}


.onboarding-title.step1 {
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 60vw; */
}

.onboarding-top-teacher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboarding-top-buttons-teacher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 10px;
  border: 1px solid #b9b9b9;
  background-color: #f6f6f6;
  margin-top: 15px;
  gap: 20px;
  margin-bottom: 15px;
}

.onboarding-body.teacher {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  padding: 30px;
  gap: 20px;
  min-height: 35vh;
}

.download-options {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;
}

.students-agreement-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
  position: relative;
}

.iframe-wrapper {
  position: relative;
  border: 1px solid #eae7e7;
  border-radius: 10px;
  width: 80%;
  height: 100%;
}

.students-agreement-container iframe {
  position: relative;
  width: 100%;
  min-height: 50vh;
  border: none;
  min-width: none;
}

.step1-teacher-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 15px;
  width: 100%;
  gap: 100px;
}

.onboarding-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  margin: 10px 0px;
}

.onboarding-section-card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: flex-start;
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 10px;
  min-height: 260px;
  border: 1px solid #eae7e7;
  gap: 5px;
}

.onboarding-section-card div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

}

.onboarding-section-downloadbtn {
  flex-direction: row !important;
}

.onboarding-section-card button {
  margin-left: 0px !important;
}

.onboarding-section-card>h6 {
  font-weight: 500;
}

.onboarding-input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.onboarding-input-container.teacher {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px;
}

.teachers-file-upload {
  padding: 2px 0px 2px 15px;
  background-color: var(--primary);
  border-radius: 5px;
  color: white;
  cursor: 'pointer';
  display: block;
  text-align: center;
}

.onboarding-input-container>h5 {
  padding-left: 10px;
}

.MuiSvgIcon-root.Mui-active {
  color: var(--primary) !important;
}

.MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
  color: var(--primary);
  font-size: 0.9rem;
}

.MuiSvgIcon-root.Mui-completed {
  color: var(--primary) !important;
}

.video-row.step3 {
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

/* ALL PAGES */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--background-grey);
  width: 100%;
  min-height: 92vh;
}

/* PAGES */

/* 404 PAGE */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  color: #333;
  font-family: Arial, sans-serif;
}

.not-found-title {
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.not-found-subtitle {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.not-found-icon {
  margin-bottom: 2rem;
}

.not-found-message {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 600px;
}

.not-found-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.not-found-button:hover {
  background-color: #303f9f;
}

/* PRIVACY POLICY + TERMS PAGES */
.privacy-policy-container,
.terms-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 80%;
  padding: 20px;
  text-align: left;
}

.privacy-policy-container h5 {
  font-size: 1.1rem;
  margin-top: 20px;
  color: var(--black-logo);
}

.terms-container h5 {
  margin-top: 40px;
  color: var(--black-logo);
}

/* PROFILE PAGE */
.password-section {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 30vw;
  background-color: white;
  padding: 20px;
  border: #999999 1px solid;
  border-radius: 10px;
  gap: 10px;
}

/* ADMIN PAGE */

.admin-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 20px;
  margin-top: 20px;
}

.admin-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  justify-content: center;
  width: 85vw;
  margin-top: 30px;
}

.admin-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 0.5px solid #b9b9b9;
}

.admin-content.element-body {
  border-top-left-radius: 10px;
}

.admin-btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.admin-search-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.admin-table-container,
.scheduleTable-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 30px;
  min-height: 20vh;
  /* max-height: 65vh !important; */
  overflow-y: auto;
  width: 100%;
}

.admin-table-container.analytics {
  min-height: 0px;
  overflow: auto !important;
  margin-top: 0px !important;
  /* border: var(--border); */
  border-radius: 10px;
  padding: 20px;
}

.admin-table-container.analytics h6 {
  margin-bottom: 20px;
}

.admin-table-container.users {
  max-height: 70vh;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.scheduleTable-container {
  overflow-x: hidden;
  /* max-height: 40vh; */
}

.scheduleTable {
  width: 100%;
  border: 1px solid #dddddd;
}

.scheduleTable th,
.scheduleTable td {
  border: 1px solid#dddddd;
}

.scheduleTable th {
  background-color: #dddddd;
  border: 1px solid #afafaf;
}

.admin-table,
.scheduleTable {
  overflow: word-wrap;
  text-align: center;
}

.admin-table th,
.scheduleTable th {
  position: sticky;
  top: 0;
  font-size: 0.85rem;
  text-align: center;
}

.admin-table td,
.scheduleTable td {
  padding: 6px;
  font-size: 0.85rem;
  text-align: center;
}

.admin-form-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0px;
  gap: 30px;
}

.general-modal-section-container,
.video-modal-section-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.generalModalSection,
.videoModalSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px 0px;
  gap: 25px;
}

.videoModalSection {
  background-color: var(--background-grey);
}

.extra-modal-section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}

.generalModalSection.extra {
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  gap: 0px;
  background-color: var(--background-grey);
  border-radius: 10px;
  min-height: 250px;
}

.modal-section-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  width: 100%;
  margin: 10px;
}

.file-upload-label {
  background-color: white !important;
  width: 100% !important;
  padding: 10px !important;
  margin: 0px !important;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  gap: 20px;
}

.file-card {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
}


.file-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.95em;
  color: #666;
  font-size: 14px;
}

.file-name,
.file-date {
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete-button {
  border: none;
  cursor: pointer;
  background-color: #f9f9f9;
  color: #666;
}

/* General */
.video-block {
  display: flex;
  justify-content: space-between;
  text-align: left;
  width: 100%;
  border-radius: 10px;
  padding: 10px 20px;
  gap: 20px;
  margin: 10px 0px;
  background-color: var(--background-grey);
}

.general-page-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-radius: 10px;
  background-color: var(--global-light-gray);
}

.video-page-modal {
  margin-top: 20px;
  min-height: 45vh;
}

.charts-row {
  display: flex;
  justify-content: space-around;
  max-width: 100vw;
  margin: 30px;
  text-align: center;
}

.chart-container div {
  width: 100%;
  padding: 30px;
  text-align: center;
}

.chart-container canvas {
  width: 55vw !important;
}

/* YOUR PROGRAM PAGE */

.program-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 90vw;
  gap: 20px;
  padding-left: 50px;
}

.program-selection-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
  /* margin-bottom: 40px; */
}

.program-selection-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5vw;
}

.program-selection-box {
  padding: 10px;
}

.sweater-girl-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sweater-girl-img {
  width: 155px;
  position: absolute;
  top: 26%;
  right: 10%;
  z-index: 0;
}

.program-table-container,
.program-table-container.program-table.element-body {
  display: flex;
  text-align: left;
  overflow: auto;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 10px !important;
  border: 1px solid #cfcece !important;
}

.program-table-container table td,
.program-table-container table th {
  text-align: center;
  font-size: 0.95rem;
  padding: 8px 12px;
  border: none;
}

.program-schedule-table th {
  position: sticky;
  top: 0;
  font-size: 1rem;
  /* background-color: whitesmoke; */
  /* border-bottom: 1.5px solid #cfcece !important; */

}

.program-schedule-table button {
  font-size: 0.9rem;
  padding: 5px !important;
}

/* SUPERADMIN PAGE */

.superAdmin-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 90%;
  margin: 10px 0px;
}

.superAdmin-table-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0px;
}

.superAdmin-table-container {
  /* make table responsive */
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 60vh;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  min-height: 40vh;
}

/* VIDEOS PAGE  */
.videos-top-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 10px 0px 50px;
}

.videos-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  gap: 60px;
}


.video-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  min-height: 40vh;
  gap: 60px;
  align-items: stretch;
  background-color: rgba(245, 245, 245, 0.192);
  padding: 20px;
  border-radius: 20px;
}



.no-video {
  flex-direction: column;
  border-top-left-radius: 20px;
  width: 80%;
  height: 40vh;
}

.video-row.no-video i {
  font-size: 3rem;
  color: var(--primary);
  margin-top: 60px;
}

.video-row.no-video h5 {
  font-size: 1rem;
  color: var(--black-logo);
  margin-top: 20px;
}


.video-box,
.quiz-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: left;
  flex: 2;
}

.quiz-box {
  flex: 1;
  width: 30vw;
}

.locked-content {
  position: relative;
  /* width: 90%; */
}

.locked-content .react-player.locked {
  opacity: 2;
  pointer-events: none;
}

.locked-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  padding: 20px;
}

.locked-overlay i {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.locked-overlay h5 {
  margin: 0;
}

/* surveyJS QUIZ (VIDEO PAGE) */
.quiz-container,
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 450px;
}


.quiz-container {
  width: 450px;
}

.quiz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 450px;
  max-height: 450px;
  padding: 30px;
}

.video-box .element-title-green {
  max-width: 40w;
}

.video-box .element-title-green h5,
.quiz-box .element-title-green h5 {
  word-wrap: break-word;
  max-width: 100%;
  font-size: 1.05rem;
}

/* animation icon inside quiz */
.quiz i,
.locked-overlay i {
  font-size: 2rem;
  padding: 20px;
  color: var(--primary);
  margin-top: 40px;
}

.sd-element--with-frame>.sd-element__erbox {
  padding-left: 25px !important;
  font-size: 1rem !important;

}

.sd-root-modern__wrapper {
  background-color: white !important;
  opacity: 1 !important;
  padding: 0px !important;

}

.sd-body {
  text-align: left;
  width: 100% !important;
  border-radius: 10px;
  border-top-left-radius: 0px;
  padding: 0px !important;

}

.sd-element__num {
  font-size: 1rem !important;
  font-weight: 500 !important;
  box-shadow: none;
  color: var(--global-black) !important;
}

.sd-item {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background-color: #edededa4;
  margin: 10px 0px;
  padding: 0px 15px !important;
  min-height: 60px;
  border-radius: 10px;
  flex-grow: 1;

}

.sd-selectbase__label {
  align-items: center;
}

.sd-item__control-label {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 0px 10px !important;
  width: 20vw !important;
}

.sd-element--with-frame {
  padding: 0px 12px !important;
  box-shadow: none !important;
  /* no scroll for x*/
  overflow-x: hidden;

}

.sv-components-column.sv-components-container-contentBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sd-description {
  display: none;
}

.sd-body__footer.sd-footer.sd-action-bar {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.sd-item--disabled .sd-item__control-label,
.sd-element__title--disabled {
  opacity: 1 !important;
}

/* error message for skipping question */
.sd-element--with-frame.sd-question--error-top {
  padding: 0px 10px;
}

/* quiz button */
.sd-btn {
  font-size: 1em !important;
  margin: 0px !important;
  background-color: var(--primary) !important;
  color: white !important;
}

.feedback-span {
  font-size: 1.1rem !important;
  text-align: center !important;
}

.sd-completedpage {
  background-color: white !important;
  height: auto !important;
  width: 100% !important;
  margin: 0px !important;
  font-size: 1rem !important;
}

.sd-completedpage h4 {
  font-size: 1.2rem !important;
}

.sd-completedpage::before {
  background-image: none !important;
}

.completed-survey {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  gap: 60px;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  gap: 20px;
}

/* RESULTS PAGE */
.results-top-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  gap: 40px;
}

.MuiTableSortLabel-icon {
  opacity: 1 !important;
  margin-left: 10px !important;
  font-size: 1.2rem !important;
}

.MuiSwitch-switchBase.Mui-checked {
  color: var(--primary) !important;
}


.student-results-table-container {
  padding: 10px !important;
}

.student-results-table {
  border: 0.5px solid #cfcece;
}

.student-results-table th {
  position: sticky;
  top: 0;
  font-size: 1rem;
  padding: 10px;
  text-align: center;
  border-bottom: 1.5px solid #cfcece !important;
}

.student-results-table td {
  text-align: center;
  font-size: 0.95rem;
  padding: 8px;
  border: 0.5px solid #cfcece !important;
}

.search-student-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* remove focus from search bar::after */
.search-student-input:focus::after {
  border: none;
  background-color: transparent;
}

/* EXTRA PAGE */
.extra-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
}

.extra-page-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
}

.magazines-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin: 30px;
  width: 100%;
  min-height: 60vh;
}

.magazine-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 340px;
  height: 520px;
  padding: 15px;
  background-color: white;
  border-radius: 20px;
  border: var(--border)
}

.magazine-title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 10px;
  font-weight: 500;
}

.magazine-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.magazine-box-btns {
  margin-top: 20px;
  width: 100%;
}

.magazine-box-btns button:hover {
  background-color: var(--global-yellow);
  color: var(--black-logo);
}

/* styling for StudentsTable.js */

.bg-green {
  background-color: #219F8E;
  color: white !important;
}

.bg-default {
  background-color: transparent;
  color: inherit;
}

/* GENERAL PAGE */
.page-content.general {
  width: 70vw;
}

.general-box-row {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  padding: 10px;
}

.general-box-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.general-box-col .element-box.video {
  align-items: center;
}

.col2 {
  width: 100%;
}

/* TEACHERS SECTIONS */
.app-box.teacher {
  background-color: var(--soft-blue);
  width: 140px;
  height: 140px;
}

.app-box.teacher:hover,
.analytics-box:hover {
  background-color: var(--global-yellow);
  cursor: pointer;
  color: var(--dark-alt);
}

.agreement-container {
  height: 400px;
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  overflow: hidden;
}

.agreement-content {
  overflow-y: auto;
  height: 100%;
  text-align: left;
  padding-right: 30px;
}

.agreement-content>h6 {
  color: var(--primary);
  font-weight: 600;
}

.agreement-content>p {
  margin: 10px 0px;
}

.sub-paragraph {
  margin: 15px 20px;
}

.sub-paragraph>p {
  margin: 5px 0px;
}

.agreement-header,
.agreement-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  text-align: center;
}

/* ATTENDANCE PAGE */
.attendance-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
  width: 100%;

}

.attendance-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 80px;
  width: 100%;
}

.attendance-table-container {
  display: flex;
  margin-top: 20px;
  max-height: 70vh;
  text-align: left;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 65vw;
  padding: 0px;
}

.attendance-table {
  overflow: word-wrap;
  width: 100%;
}

.attendance-table th {
  position: sticky;
  top: 0;
  font-size: 0.95rem;
  text-align: center;
  background-color: white;
  opacity: 1;
  z-index: 1;
  border-bottom: 2px solid #ddd;
  height: 60px;
}

.attendance-table td {
  text-align: center;
  font-size: 1rem;
}

.attendance-select-excused {
  background-color: var(--yellow-soft) !important;
  border-radius: 10px;
}

.attendance-select-present {
  background-color: var(--primary) !important;
  border-radius: 10px;
  color: white !important;
}

.attendance-select-absent {
  background-color: var(--secondary) !important;
  border-radius: 10px;
  color: white !important;
}


/* LOADING ANIMATION */
.loading-container,
.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  background-color: rgba(166, 166, 166, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.spinner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(243, 243, 243, 0.815);
  width: 100%;
  height: 100%;
}

.spinner-content-iframe {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  inset: 0;
  z-index: 10;
}

/* MEDIA */

/* LANDING PAGE */
@media (max-width: 1024px) {

  .hero-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }

  .hero-left-container h1 {
    font-size: 2rem;
    line-height: 40px;
  }

  .hero-left-container p {
    font-size: 1.2rem;
  }


  .heroSection p,
  .heroSection h2 {
    width: 85vw;
    font-size: 1.2rem;
  }

  .heroSection h1 {
    font-size: 1.3rem;
    width: 80vw;
  }

  .featureSection {
    gap: 0px;
  }

  .lottie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh;
    width: 100%;
    margin: 20px;
  }

  .featureSection-right-container {
    padding: 20px;
  }

  .featureSection.div3 {
    width: 100%;
  }

  .landing-footer {
    flex-direction: column;
    gap: 15px;

  }

  .landing-footer a,
  .landing-footer p {
    font-size: 0.9rem;
    line-height: 1.6;
  }
}

@media (max-width: 700px) {
  .landing-container {
    padding: 10px;
  }

  .featureSection {
    padding: 20px;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 15px;
  }

  .landing-header {
    padding: 10px 12px;
  }

  .landing-header .MuiSelect-select {
    padding: 6px 15px !important;
  }

  .loginLPButton {
    background-color: var(--black-logo);
    color: white !important;
    border: none;
    border-radius: 5px;
    padding: 8px 10px;
  }

  .landing-logo h1 {
    font-size: 1.2rem;
    padding-left: 10px;
  }

  .hero-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0px;
  }

  .hero-left-container,
  .hero-right-container {
    align-items: center;
    text-align: center;
    padding: 10px;
  }


  .hero-left-container h1 {
    font-size: 1.8rem;
  }

  .hero-left-container p {
    text-align: center;
    font-size: 1.3rem;
  }

  .hero-section p {
    font-size: 1.3rem;
  }

  .featureSection.div1>.featureSection-right-container,
  .featureSection.div2>.featureSection-right-container,
  .featureSection.div3>.featureSection-right-container,
  .featureSection.div4>.featureSection-right-container {
    padding: 0px;
  }

  .featureSection-right-container h5,
  .featureSection-right-container h5,
  .featureSection-right-container h5 {
    font-size: 1.2rem;
  }

  .featureSection.div1 p,
  .featureSection.div2 p,
  .featureSection.div3 p,
  .featureSection.div4 p {
    font-size: 1.2rem;
  }

  .div4 img {
    /* width: 60% !important; */
  }

  .featureSection.div2 {
    flex-direction: column
  }


  .featureSection-right-container {
    text-align: center;
    align-items: center;
  }

  .landing-footer div {
    flex-direction: column;
    gap: 10px;
  }

}

/* TERMS PAGE */
@media (max-width: 700px) {

  .terms-container,
  .privacy-policy-container {
    padding-bottom: 40px;
  }

  .terms-container h5 {
    font-size: 1.1rem;
  }
}

/* Main Layout */
@media (max-width: 1000px) {
  .page-container {
    min-height: 100vh;
    padding: 0px;
  }

  .page-content {
    padding: 0px 25px;
  }

  .element-box-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .element-box {
    width: 100%;
  }
}

/* LOGIN page */
@media (max-width: 1000px) {
  .login-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

@media (max-width: 600px) {
  .login-form-container {
    width: 80vw;
    padding: 20px;
  }

  .login-form {
    width: 75vw;
  }

  .login-form-title h2 {
    font-size: 1.5rem;
  }

  .signin-help button,
  .signin-help a {
    font-size: 0.85rem;
  }
}

@media (max-width: 600px) {
  .page-title {
    width: 80vw;
    padding: 20px;
    gap: 10px;
  }

  .page-title>h3 {
    font-size: 1.3rem;
  }

}

/* Media: Header */
@media (max-width: 600px) {

  .welcome-message {
    display: none;
  }


  .logo-text>h4 {
    font-size: 1.3rem;
    letter-spacing: 0.3px;
  }

  .logo-img {
    width: 45px;
    margin-right: 15px;
  }

  .menu-items {
    margin-right: 0px;
    margin: 20px;
  }

  .dropdown-menu {
    width: 100%;
    display: flex;
    justify-content: center;

    min-height: 50vh;
    box-shadow: inset 0px 0px 10px 0px rgba(131, 131, 131, 0.1);
  }

  .dropdownItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 20px;
    font-size: 1.1rem;
    text-align: left;
  }

  .dropdownItem button {
    font-size: 1.1rem;
  }

  .mobile-closing {
    display: flex;
    width: 100%;
    margin: 30px 0px;
    cursor: pointer;
  }

  .tooltip {
    font-size: 1rem;
    font-weight: 400;
    width: 60vw !important;
    z-index: 9999;
  }
}

/* Media: Footer */
@media (max-width: 1000px) {
  .footer {
    display: none;
  }
}

/* PROFILE PAGE */
@media (max-width: 600px) {
  .password-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 15px;
    width: 100%;
  }
}

/* Media: HOMEPAGE */
@media (max-width: 1100px) {

  .homepage-main-container {
    display: flex;
    flex-direction: column;
    min-height: 40vh;
    padding: 20px;
  }

  .homepage-title h2 {
    font-size: 1.4rem;
  }

  .app-box {
    width: 130px;
    height: 120px;
    font-size: 1.1rem;
    margin: 5px;
  }

  .ck-app-box {
    width: 130px;
    height: 120px;
    font-size: 1.1rem;
    margin: 5px;
  }

  .ck-bg-icon>img {
    width: 50px;
    top: 80px;
    rotate: 85deg;
    margin-left: 30px;
  }

  .ck-bg-icon-green>img {
    width: 60px;
    top: 180px;
    rotate: 35deg;
    margin-right: 30px;
  }


  .onboarding-banner p {
    padding: 15px;
    text-align: left;
  }

}

@media (max-width: 600px) {
  .homepage-container {
    padding: 30px 20px;
  }

  .homepage-main-container {
    width: 90vw;
  }

  .homepage-title {
    width: 80vw;
  }

  .homepage-title h2 {
    font-size: 1.3rem;
  }

  .homepage-title h4 {
    font-size: 1rem;
  }

  .apps-container {
    /* flex-wrap: wrap; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .homepage-alert {
    margin: 10px 0px 20px;
    width: 80vw;
  }

  .homepage-onboarding-text {
    width: 80vw !important;
    padding: 15px;
  }

  .app-box {
    width: 100px;
    height: 90px;
    font-size: 0.9rem;
  }

  .ck-app-box {
    width: 130px;
    height: 120px;
    font-size: 0.85rem;
  }

  .ck-button-icon>img {
    width: 20px !important;
  }

  .ck-button-icon .ck-button-green {
    width: 25px !important;
  }


  .onboarding-banner p {
    font-size: 0.9rem;
  }

  .onboarding-banner button {
    font-size: 0.9rem;
  }
}


/* Media: ONBOARDING FLOW */
@media (max-width: 1000px) {

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel,
  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    font-size: 0.85rem;
  }

  .onboarding-body {
    flex-direction: column !important;
    gap: 30px;
    /* margin-bottom: 20px; */
  }

  .onboarding-body.teacher {
    gap: 20px;
  }

  .onboarding-input-container {
    width: 100%;
  }

  .onboarding-title {
    /* padding: 0px 20px; */
    text-align: left;
  }

  .onboarding-section-card-container {
    gap: 20px;
  }
}

@media (max-width: 600px) {
  .onboarding-page {
    background-color: white !important;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel,
  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
    font-size: 0.8rem;
  }

  .onboarding-content-container {
    width: 100%;
    padding: 15px;
    margin-top: 10px;
  }

  .teacher-agreement-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }

  .onboarding-top-buttons-teacher {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
    padding: 10px;
    width: 80vw;
  }

  .students-agreement-container {
    padding: 5px;
  }

  .agreement-container.teachers {
    min-height: 60vh;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 10px;

  }

  .onboarding-body {
    padding: 0px 5px;
    flex-direction: column !important;
  }

  .onboarding-section {
    padding: 0px;
  }

  .onboarding-input-container>h5 {
    padding-left: 0px;
  }

  .iframe-wrapper {
    width: 100%;
  }

  .onboarding-title.step1 {
    margin-bottom: 5px;
    padding: 5px;
  }
}

/* Media: Video Page */
@media (max-width: 1100px) {

  /* change layout to column */
  .videos-top-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .videos-main-container {
    padding: 20px;
  }

  .react-player {
    width: 450px !important;
    height: 35vh !important;
  }

  .video-row {
    flex-direction: column;
    align-content: center;
  }

  .quiz-box {
    width: 100%;
  }

  .video-container,
  .quiz-container {
    width: 100%;
    height: 45vh;
    border-radius: 20px;
  }

  /* quiz */
  .sv-body,
  .sv-question,
  .sv-title {
    font-size: 0.95rem !important;
    line-height: 0.9rem !important;
  }

  .sv-title {
    padding: 10px !important;
    font-size: 1.1rem !important;
  }

  .sd-body {
    width: 100% !important;
  }

  .sd-item__control-label {
    width: 35vw !important;
  }

}

@media (max-width: 600px) {

  .videos-main-container {
    text-align: left;
    gap: 20px;
  }

  .video-row {
    background-color: 'gray' !important;
  }

  .video-box .element-title-green {
    width: 80%;
    font-size: 1rem;
  }

  .video-box .element-title-green h5,
  .quiz-box .element-title-green h5 {
    font-size: 1rem;
  }

  .react-player {
    width: 70vw !important;
    height: 25vh !important;
  }

  .video-container,
  .quiz-container {
    /* background-color: pink; */
    width: 100% !important;
    min-height: 20vh !important;
    height: fit-content;
  }

  .quiz-container {
    border-top-left-radius: 0%;
  }

  .sd-element--with-frame>.sd-element__erbox {
    padding-left: 25px !important;
    font-size: 1rem !important;
  }

  .sd-item__control-label {
    width: 60vw !important;
  }

  .quiz {
    width: 80vw;
    max-height: 600px;
  }

  .video-player-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
  }

  .video-player-btns button {
    font-size: 0.9rem;
    padding: 10px;
  }

  .video-player-btns button:hover {
    background-color: var(--primary);
    color: white;
  }

  .video-player-btns button:disabled {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:hover {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:active {
    background-color: var(--primary);
    color: white;
  }

  .video-player-btns button:active:hover {
    background-color: var(--primary);
    color: white;
  }

  .video-player-btns button:active:disabled {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:active:disabled:hover {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:active:disabled:active {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active:hover {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active:disabled {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active:disabled:hover {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active:disabled:active {
    background-color: var(--global-light-gray);
    color: var(--global-black);
  }

  .video-player-btns button:disabled:active:disabled:active:hover {
    background-color: var(--global-light-gray);
    color: var
  }



  .sv-body,
  .sv-question,
  .sv-title {
    font-size: 0.95rem !important;
    line-height: 0.9rem !important;
  }

  .sv-title {
    line-height: 1rem !important;
    font-size: 1rem !important;
  }


}

/*media: Your Program page */
@media (max-width: 1000px) {

  .program-selection-box>h6 {
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .program-selection-section {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .program-selection-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .program-selection-box>h6 {
    font-size: 0.95rem;
  }
}



/* Media: General Page */
@media (max-width: 1000px) {
  .page-content.general {
    width: 100vw;
  }

  .general-box-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    margin-top: 0px;
    padding-top: 0px;
  }

  .general-box-col {
    width: 100%;
  }

  .col2 {
    width: 100%;
  }
}

/* EXTRA page */
@media (max-width: 1000px) {
  .magazines-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media (max-width: 800px) {
  .magazines-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

}

/* Media: results page */
@media screen and (max-width: 1000px) {
  .MuiTableCell-root {
    padding: 5px !important;
    font-size: 0.8rem !important;
  }

  .results-top-buttons {
    flex-direction: column;
    gap: 0px;

  }
}

/* Attendance page */
@media (max-width: 1000px) {
  .attendance-header {
    flex-direction: column;
    gap: 15px;
  }

  .attendance-table-container {
    width: 100%;
  }

}

/* ADMIN PAGE */
@media (max-width: 1000px) {
  .charts-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 30px;
  }

  .user-bar-chart canvas,
  .program-pie-chart canvas {
    min-height: 30vh !important;
    width: 100% !important;
  }
}

/* extra activities modal */

@media (max-width: 1200px) {

  .file-grid {
    grid-template-columns: repeat(2, 1fr);

  }
}

@media (max-width: 600px) {

  .file-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}